import React, { Component } from 'react'

export default class Header extends Component {
  render() {
    return (
      <div>
        <header className="main-header header-light header-mobile-light menu-dark menu-mobile-dark sm-hidden" data-section="header">
          <div className="header-container">
            <div className="header-content">
              <div className="header-el">
                <div className="topline" />
                <div className="header-block brand-block">
                  <div className="logo-block">
                    <a className="header-logo load-content" href="/">
                      <img src="images/logos/GaytanLogoComplete_Grn.jpg" alt="Gaytan & Leevan, LLP - CPA Los Angeles" className="logo-light" />
                      <img src="images/logos/GaytanLogoComplete_Grn.jpg" alt="Gaytan & Leevan, LLP - CPA Los Angeles" className="logo-dark" />
                    </a>
                  </div>
                  <button type="button" className="nav-toggle">
                    <span />
                  </button>
                </div>

                <nav className="header-block nav-block header-nav">
                  <ul className="nav">
                    <li><a href="/" className="load-content">Home</a></li>
                    <li><a href="#about" className="load-content">About</a></li>
                    <li><a href="#services" className="load-content">Services</a></li>
                    {/* <li><a href="#marketing" className="load-content">Marketing</a></li> */}
                     <li><a href="#contact" className="load-content">Contact</a></li>
                  </ul>
                </nav>
                <p className="address g-text">11400 West Olympic Blvd. 16th Floor
                  <br />Los Angeles, California 90064
                  <br />Phone: (310) 477-5252
                  <br />Fax: (310) 478-5535
                </p>
                <p className="g-text">Member-American Institute Of Certified Public Accountants<br />
                  And California Society Of Certified Public Accountants</p>
                <div className="bottomline" />
              </div>
            </div>
          </div>
        </header>
      </div>
    )
  }
}
