// import React from 'react';
import React, { Component } from 'react';
import { HashRouter } from "react-router-dom";

import './App.css';

import Header from './components/header';
import About from './components/about';
import Services from './components/services';
import Parallax from './components/parallax'
// import Marketing from './components/marketing';
// import Parallax2 from './components/parallax2';
import Contact from './components/contact';
import Footer from './components/footer';

class App extends Component {
  static defaultProps = {
    center: { lat: 34.036370, lng: -118.442950 },
    zoom: 10
  }
  render() {
  return (
    <HashRouter basename='/'>
    <div>
      <div className="main-container">
        <Header></Header>
        <About></About>
        <Services></Services>
        <Parallax></Parallax>
        {/* <Marketing></Marketing> */}
        {/* <Parallax2></Parallax2> */}
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
    </HashRouter>
  );
}
}

export default App;
